import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = { class: "ocn-list-item" };
const _hoisted_2 = ["title"];
const _hoisted_3 = { class: "ocn-list-item" };
const _hoisted_4 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_5 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_6 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_7 = {
    key: 1,
    class: "ocn-list-item"
};
const _hoisted_8 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_9 = {
    key: 2,
    class: "ocn-list-item"
};
const _hoisted_10 = ["href", "data-ga-label", "data-ga4-details"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'Instructors',
    props: {
        t: {
            type: Object,
            default: () => ({
                instructorsText: 'Instructors',
                becomeAnInstructorText: 'Become an Instructor',
                virtualTrainingsText: 'Virtual Trainings',
                findATrainingText: 'Find a Training',
                viewAllTrainingsText: 'View All Trainings',
                findAnInstructorText: 'Find an Instructor',
            })
        },
        active: { type: Object, default() { return {}; } },
        currentUserLocale: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        isStrongVirtualHeaderMenuEnabled: { type: String, default: '' },
    },
    setup(__props) {
        const isEmpty = (obj) => Object.keys(obj ?? {}).length == 0;
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("li", _hoisted_1, [
                _createElementVNode("a", {
                    class: _normalizeClass(["more-below ocn-list-link", { 'js-active-item inactive-item': !isEmpty(props.active?.['Instructors']) }]),
                    href: "#",
                    title: __props.t.instructorsText
                }, _toDisplayString(__props.t.instructorsText), 11, _hoisted_2),
                _createElementVNode("ul", {
                    class: "ocn-subnav",
                    style: _normalizeStyle(!isEmpty(props.active?.['Instructors']) ? 'display: block;' : '')
                }, [
                    _createElementVNode("li", _hoisted_3, [
                        _createElementVNode("a", {
                            href: '/' + props.currentUserLocale + '/become-an-instructor',
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': (props.active?.['Instructors']?.['/become-an-instructor']) }]),
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Instructors_Become an Instructor",
                            "data-ga-label": props.gaLabel,
                            "data-ga4-event-name": "navigation_menu",
                            "data-ga4-action": "Instructors_Become an Instructor",
                            "data-ga4-details": props.gaLabel
                        }, _toDisplayString(__props.t.becomeAnInstructorText), 11, _hoisted_4)
                    ]),
                    (props.isStrongVirtualHeaderMenuEnabled)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/become-an-instructor-virtual',
                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': (props.active?.['Instructors']?.['/become-an-instructor-virtual']) }]),
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Instructors_View Virtual Trainings",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Instructors_View Virtual Trainings",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(__props.t.virtualTrainingsText), 11, _hoisted_6)
                        ]))
                        : _createCommentVNode("", true),
                    (props.isStrongVirtualHeaderMenuEnabled)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/training/nearby',
                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': (props.active?.['Instructors']?.['/training/nearby']) }]),
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Instructors_View Find_A_Training Trainings",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Instructors_View Find_A_Training Trainings",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(__props.t.findATrainingText), 11, _hoisted_8)
                        ]))
                        : (_openBlock(), _createElementBlock("li", _hoisted_9, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/training/nearby',
                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': (props.active?.['Instructors']?.['/training/nearby']) }]),
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Instructors_View All Trainings",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Instructors_View All Trainings",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(__props.t.viewAllTrainingsText), 11, _hoisted_10)
                        ])),
                    _renderSlot(_ctx.$slots, "default")
                ], 4)
            ]));
        };
    }
});
