import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_2 = ["href", "title", "data-ga-label", "data-ga4-details"];
const _hoisted_3 = { class: "ocn-list-item" };
const _hoisted_4 = ["href", "title", "data-ga-label", "data-ga4-details"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'Benefits',
    props: {
        t: {
            type: Object,
            default: () => ({
                memberBenefitsText: 'Member Benefits',
                strongForGymsText: 'STRONG for Gyms'
            })
        },
        active: { type: Object, default() { return {}; } },
        currentUserLocale: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        shouldShowJoinLink: { type: String, default: '' },
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                (props.shouldShowJoinLink)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
                        _createElementVNode("a", {
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Benefits']?.['/member-benefits'] }]),
                            href: '/' + props.currentUserLocale + '/member-benefits',
                            title: __props.t.memberBenefitsText,
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Member Benefits",
                            "data-ga-label": props.gaLabel,
                            "data-ga4-event-name": "navigation_menu",
                            "data-ga4-action": "Member Benefits",
                            "data-ga4-details": props.gaLabel
                        }, _toDisplayString(__props.t.memberBenefitsText), 11, _hoisted_2)
                    ]))
                    : _createCommentVNode("", true),
                _createElementVNode("li", _hoisted_3, [
                    _createElementVNode("a", {
                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Benefits']?.['/clubs/business_perks'] }]),
                        href: '/' + props.currentUserLocale + '/clubs/business_perks',
                        title: __props.t.strongForGymsText,
                        "data-ga-category": "Navigation Menu",
                        "data-ga-action": "STRONG for Gyms",
                        "data-ga-label": props.gaLabel,
                        "data-ga4-event-name": "navigation_menu",
                        "data-ga4-action": "STRONG for Gym",
                        "data-ga4-details": props.gaLabel
                    }, _toDisplayString(__props.t.strongForGymsText), 11, _hoisted_4)
                ])
            ], 64));
        };
    }
});
