import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_2 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_3 = ["href", "data-ga-label"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'Sessions',
    props: {
        t: { type: Object, default: () => ({ sessionsSyncLabsText: 'SYNC Labs' }) },
        active: { type: Object, default() { return {}; } },
        currentUserLocale: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        isAuthenticated: { type: String, default: '' },
        hasPreMembershipLinksOnly: { type: String, default: '' },
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (props.isAuthenticated)
                ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
                    (props.hasPreMembershipLinksOnly)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(__props.t.sessionsSyncLabsText), 1))
                        : (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            href: '/' + props.currentUserLocale + '/sync_labs',
                            class: _normalizeClass([{ 'js-active-item active-item': (props.active?.['Instructors']?.['/instructor']) }, "ocn-list-link"]),
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Instructors_SYNC Labs",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(__props.t.sessionsSyncLabsText), 11, _hoisted_3))
                ]))
                : _createCommentVNode("", true);
        };
    }
});
