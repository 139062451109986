import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = { class: "ocn-list-item" };
const _hoisted_2 = ["title"];
const _hoisted_3 = { class: "ocn-list-item" };
const _hoisted_4 = ["href", "title", "data-ga-label", "data-ga4-details"];
const _hoisted_5 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_6 = ["href"];
const _hoisted_7 = { class: "ocn-list-item" };
const _hoisted_8 = ["href"];
const _hoisted_9 = { class: "ocn-list-item" };
const _hoisted_10 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_11 = { class: "ocn-list-item" };
const _hoisted_12 = ["href", "data-ga-label", "data-ga4-details"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'Classes',
    props: {
        t: {
            type: Object,
            default: () => ({
                classesText: 'Classes',
                findAnInPersonClassText: 'Find An In-Person Class',
                findAVirtualClassText: 'Find a Virtual Class',
                findAClassText: 'Find a Class',
                findAnInstructorText: 'Find an Instructor',
                aboutTheClassText: 'About the Class'
            })
        },
        active: { type: Object, default() { return {}; } },
        currentUserLocale: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        onlineClassBaseUrl: { type: String, default: '' },
        requestQueryCat: { type: String, default: '' },
        classLocatorEnabled: { type: String, default: '' },
        isCirclUrl: { type: String, default: '' },
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("li", _hoisted_1, [
                _createElementVNode("a", {
                    class: "more-below ocn-list-link",
                    href: "#",
                    title: __props.t.classesText
                }, _toDisplayString(__props.t.classesText), 9, _hoisted_2),
                _createElementVNode("ul", {
                    class: "ocn-subnav",
                    style: _normalizeStyle(props.active?.['Classes'] ? 'display: block;' : '')
                }, [
                    _createElementVNode("li", _hoisted_3, [
                        _createElementVNode("a", {
                            href: '/' + props.currentUserLocale + '/party',
                            class: _normalizeClass([{ 'js-active-item active-item': (props.active?.['Classes']?.['/pages/class']) }, "ocn-list-link"]),
                            title: __props.t.aboutTheClassText,
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Classes_About the Classes",
                            "data-ga-label": props.gaLabel,
                            "data-ga4-event-name": "navigation_menu",
                            "data-ga4-action": "Classes_About the Classes",
                            "data-ga4-details": props.gaLabel
                        }, _toDisplayString(__props.t.aboutTheClassText), 11, _hoisted_4)
                    ]),
                    (props.classLocatorEnabled)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/class_search' + (props.isCirclUrl ? '?class-type=circl-mobility' : ''),
                                class: _normalizeClass([{ 'js-active-item active-item': (props.active?.['Classes']?.['/class_search']) }, "ocn-list-link"]),
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Classes_Find a Class",
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Classes_Find a Class"
                            }, _toDisplayString(__props.t.findAClassText), 11, _hoisted_6)
                        ]))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createElementVNode("li", _hoisted_7, [
                                _createElementVNode("a", {
                                    href: '/' + props.currentUserLocale + '/party/nearMe',
                                    class: _normalizeClass([{ 'js-active-item active-item': (props.active?.['Classes']?.['/party'] && props.requestQueryCat) }, "ocn-list-link"]),
                                    "data-ga-category": "Navigation Menu",
                                    "data-ga-action": "Classes_Find a Class",
                                    "data-ga4-event-name": "navigation_menu",
                                    "data-ga4-action": "Classes_Find a Class"
                                }, _toDisplayString(__props.t.findAnInPersonClassText), 11, _hoisted_8)
                            ]),
                            _createElementVNode("li", _hoisted_9, [
                                _createElementVNode("a", {
                                    href: props.onlineClassBaseUrl + '/?locale=' + props.currentUserLocale.replace('-', '_') + '&classType=STRONG+Nation',
                                    class: "ocn-list-link",
                                    "data-ga-category": "Navigation Menu",
                                    "data-ga-action": "Classes_Find a Virtual Class",
                                    "data-ga-label": props.gaLabel,
                                    "data-ga4-event-name": "navigation_menu",
                                    "data-ga4-action": "Classes_Find a Virtual Class",
                                    "data-ga4-details": props.gaLabel
                                }, _toDisplayString(__props.t.findAVirtualClassText), 9, _hoisted_10)
                            ])
                        ], 64)),
                    _createElementVNode("li", _hoisted_11, [
                        _createElementVNode("a", {
                            href: '/' + props.currentUserLocale + '/instructor',
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Classes']?.['/instructor'] }]),
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Classes_Find an Instructor",
                            "data-ga-label": props.gaLabel,
                            "data-ga4-event-name": "navigation_menu",
                            "data-ga4-action": "Classes_Find an Instructor",
                            "data-ga4-details": props.gaLabel
                        }, _toDisplayString(__props.t.findAnInstructorText), 11, _hoisted_12)
                    ])
                ], 4)
            ]));
        };
    }
});
