import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_2 = { class: "gh-menu-nav" };
const _hoisted_3 = {
    key: 0,
    class: "gh-main-nav-item"
};
const _hoisted_4 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_5 = {
    id: "nav-trainings",
    class: "text-left dropdown-mtv dd-right"
};
const _hoisted_6 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_7 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_8 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_9 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_10 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_11 = { key: 0 };
const _hoisted_12 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_13 = { class: "gh-main-nav-item" };
const _hoisted_14 = ["href", "title", "data-ga-label", "data-ga4-details"];
const _hoisted_15 = {
    id: "nav-classes",
    class: "text-left dropdown-mtv dd-right"
};
const _hoisted_16 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_17 = { key: 0 };
const _hoisted_18 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_19 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_20 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_21 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_22 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_23 = { class: "gh-main-nav-item" };
const _hoisted_24 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_25 = {
    id: "nav-trainings",
    class: "text-left dropdown-mtv dd-right"
};
const _hoisted_26 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_27 = { key: 0 };
const _hoisted_28 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_29 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_30 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_31 = { key: 1 };
const _hoisted_32 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_33 = { class: "gh-main-nav-item" };
const _hoisted_34 = ["href", "title", "data-ga-label", "data-ga4-details"];
import { reactive, onMounted, computed } from 'vue';
import MenuButton from '../nav/header/hamburger/Zumba/MenuButton.vue';
import { todayISO } from '@ts/Util/datetime';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SHeader',
    props: {
        t: {
            type: Object,
            default: () => ({
                aboutText: 'About',
                aboutTheClassText: 'About the Class',
                becomeAnInstructorText: 'Become an Instructor',
                classesTitleCaseText: 'Classes',
                findAClassText: 'Find a Class',
                findATrainingText: 'Find A Training',
                findAVirtualClassText: 'Find a Virtual Class',
                findAnInPersonClassText: 'Find An In-Person Class',
                findAnInstructorText: 'Find an Instructor',
                forGymsText: 'For Gyms',
                instructorTrainingsText: 'Instructor Trainings',
                memberBenefitsText: 'Member Benefits',
                navigationText: 'Navigation',
                ourStoryText: 'Our Story',
                resultsText: 'Results',
                shopText: 'Shop Clothing',
                syncLabsText: 'SYNC Labs',
                viewAllTrainingsText: 'View All Trainings',
                virtualTrainingsText: 'Virtual Trainings',
                hireAnInstructorText: 'Hire an Instructor',
                postAJobText: 'Post A Job',
            })
        },
        currentUserLocale: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        headerFooterStyle: { type: String, default: 'full' },
        instructorTrainingsLink: { type: String, default: '' },
        isCmsShopBrandingZwEnabled: { type: String, default: '' },
        isStrongResultsMenuEnabled: { type: String, default: '' },
        isStrongVirtualHeaderMenuEnabled: { type: String, default: '' },
        isZjRefactorNavigationEnabled: { type: String, default: '' },
        localizationUser: { type: String, default: '' },
        onlineClassBaseUrl: { type: String, default: '' },
        smtPublicCeaseStrongShopRedirection: { type: String, default: '' },
        isAuthenticated: { type: String, default: '' },
        shopUrl: { type: String, default: '' },
        shopUrlWithRedirection: { type: String, default: '' },
        isTrainingStrongRegistrationFlowEnabled: { type: String, default: '' },
        classLocatorEnabled: { type: String, default: '' },
        isCirclUrl: { type: String, default: '' },
        isJobBoardFormEnabled: { type: String, default: '' },
        hideMobileMenu: { type: Boolean, default: false },
        isGymPostAJobEnabled: { type: Boolean, default: false }
    },
    setup(__props) {
        const props = __props;
        const hideNavigationElements = computed(() => {
            return props.headerFooterStyle === 'minimal';
        });
        const state = reactive({
            isSticky: false
        });
        onMounted(() => {
            addStickyObserver();
        });
        const addStickyObserver = () => {
            const headerElement = document.getElementById('header-toggle-component');
            const observerOptions = { threshold: 0.1 };
            const toggleIsSticky = entries => state.isSticky = entries[0].intersectionRatio < 0.1;
            const observer = new IntersectionObserver(toggleIsSticky, observerOptions);
            if (!headerElement)
                return;
            observer.observe(headerElement);
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("header", {
                class: _normalizeClass(["global-header", {
                        'sticky': state.isSticky,
                        'no-clickable': __props.hideMobileMenu
                    }])
            }, [
                (props.isCmsShopBrandingZwEnabled)
                    ? _renderSlot(_ctx.$slots, "strongheaderlogo", { key: 0 })
                    : (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        href: '/' + props.localizationUser,
                        title: "Strong Nation®",
                        class: "gh-logo-strong-nation",
                        "data-ga-category": "Global Logo",
                        "data-ga-action": "Click",
                        "data-ga-label": props.gaLabel,
                        "data-ga4-event-name": "global_logo",
                        "data-ga4-action": "Click",
                        "data-ga4-details": props.gaLabel
                    }, null, 8, _hoisted_1)),
                _createElementVNode("div", _hoisted_2, [
                    (!props.hideMobileMenu)
                        ? (_openBlock(), _createBlock(MenuButton, {
                            key: 0,
                            "navigation-text": props.t.navigationText
                        }, null, 8, ["navigation-text"]))
                        : _createCommentVNode("", true),
                    _createElementVNode("ul", {
                        class: _normalizeClass(["gh-main-nav", { 'hide-nav-ele': hideNavigationElements.value }])
                    }, [
                        (props.isStrongResultsMenuEnabled)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                                _createElementVNode("a", {
                                    class: "gh-main-nav-link",
                                    href: '/' + props.currentUserLocale + '/party',
                                    "data-ga-category": "Navigation Menu",
                                    "data-ga-action": "About",
                                    "data-ga-label": props.gaLabel,
                                    "data-ga4-event-name": "navigation_menu",
                                    "data-ga4-action": "About",
                                    "data-ga4-details": props.gaLabel
                                }, [
                                    _createTextVNode(_toDisplayString(props.t.aboutText) + " ", 1),
                                    _createElementVNode("ul", _hoisted_5, [
                                        _createElementVNode("li", null, [
                                            _createElementVNode("a", {
                                                href: '/' + props.currentUserLocale + '/party',
                                                "data-ga-category": "Navigation Menu",
                                                "data-ga-action": "About_About the Class",
                                                "data-ga-label": props.gaLabel,
                                                "data-ga4-event-name": "navigation_menu",
                                                "data-ga4-action": "About_About the Class",
                                                "data-ga4-details": props.gaLabel
                                            }, _toDisplayString(props.t.aboutTheClassText), 9, _hoisted_6)
                                        ]),
                                        _createElementVNode("li", null, [
                                            _createElementVNode("a", {
                                                href: '/' + props.currentUserLocale + '/about',
                                                "data-ga-category": "Navigation Menu",
                                                "data-ga-action": "About_Our Story",
                                                "data-ga-label": props.gaLabel,
                                                "data-ga4-event-name": "navigation_menu",
                                                "data-ga4-action": "About_Our Story",
                                                "data-ga4-details": props.gaLabel
                                            }, _toDisplayString(props.t.ourStoryText), 9, _hoisted_7)
                                        ]),
                                        _createElementVNode("li", null, [
                                            _createElementVNode("a", {
                                                href: '/' + props.currentUserLocale + '/member-benefits',
                                                "data-ga-category": "Navigation Menu",
                                                "data-ga-action": "About_Member Benefits",
                                                "data-ga-label": props.gaLabel,
                                                "data-ga4-event-name": "navigation_menu",
                                                "data-ga4-action": "About_Member Benefits",
                                                "data-ga4-details": props.gaLabel
                                            }, _toDisplayString(props.t.memberBenefitsText), 9, _hoisted_8)
                                        ]),
                                        _createElementVNode("li", null, [
                                            _createElementVNode("a", {
                                                href: '/' + props.currentUserLocale + '/results',
                                                "data-ga-category": "Navigation Menu",
                                                "data-ga-action": "About_Results",
                                                "data-ga-label": props.gaLabel,
                                                "data-ga4-event-name": "navigation_menu",
                                                "data-ga4-action": "About_Results",
                                                "data-ga4-details": props.gaLabel
                                            }, _toDisplayString(props.t.resultsText), 9, _hoisted_9)
                                        ]),
                                        _createElementVNode("li", null, [
                                            _createElementVNode("a", {
                                                href: '/' + props.currentUserLocale + '/clubs',
                                                "data-ga-category": "Navigation Menu",
                                                "data-ga-action": "About_For Gyms",
                                                "data-ga-label": props.gaLabel,
                                                "data-ga4-event-name": "navigation_menu",
                                                "data-ga4-action": "About_For Gyms",
                                                "data-ga4-details": props.gaLabel
                                            }, _toDisplayString(props.t.forGymsText), 9, _hoisted_10)
                                        ]),
                                        (props.isJobBoardFormEnabled)
                                            ? (_openBlock(), _createElementBlock("li", _hoisted_11, [
                                                _createElementVNode("a", {
                                                    href: '/' + props.currentUserLocale + '/clubs#job-form',
                                                    "data-ga-category": "Navigation Menu",
                                                    "data-ga-action": "About_Hire an Instructor",
                                                    "data-ga-label": props.gaLabel,
                                                    "data-ga4-event-name": "navigation_menu",
                                                    "data-ga4-action": "About_Hire an Instructor",
                                                    "data-ga4-details": props.gaLabel
                                                }, _toDisplayString(props.t.postAJobText), 9, _hoisted_12)
                                            ]))
                                            : _createCommentVNode("", true)
                                    ])
                                ], 8, _hoisted_4)
                            ]))
                            : _createCommentVNode("", true),
                        _createElementVNode("li", _hoisted_13, [
                            _createElementVNode("a", {
                                class: "gh-main-nav-link",
                                href: '/' + props.localizationUser + '/party',
                                title: props.t.classesTitleCaseText,
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Classes",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Classes",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.t.classesTitleCaseText), 9, _hoisted_14),
                            _createElementVNode("ul", _hoisted_15, [
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: '/' + props.currentUserLocale + '/party',
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "About_About our Classes",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "navigation_menu",
                                        "data-ga4-action": "About_About our Classes",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.aboutTheClassText), 9, _hoisted_16)
                                ]),
                                (props.classLocatorEnabled)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_17, [
                                        _createElementVNode("a", {
                                            href: '/' + props.currentUserLocale + '/class_search' + (props.isCirclUrl ? '?class-type=circl-mobility' : ''),
                                            "data-ga-category": "Navigation Menu",
                                            "data-ga-action": "About_Find a Class",
                                            "data-ga-label": props.gaLabel,
                                            "data-ga4-event-name": "navigation_menu",
                                            "data-ga4-action": "About_Find a Class",
                                            "data-ga4-details": props.gaLabel
                                        }, _toDisplayString(props.t.findAClassText), 9, _hoisted_18)
                                    ]))
                                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                        _createElementVNode("li", null, [
                                            _createElementVNode("a", {
                                                href: '/' + props.currentUserLocale + '/party/nearMe?date=' + _unref(todayISO)(),
                                                "data-ga-category": "Navigation Menu",
                                                "data-ga-action": "About_Find a Class",
                                                "data-ga-label": props.gaLabel,
                                                "data-ga4-event-name": "navigation_menu",
                                                "data-ga4-action": "About_Find a Class",
                                                "data-ga4-details": props.gaLabel
                                            }, _toDisplayString(props.t.findAnInPersonClassText), 9, _hoisted_19)
                                        ]),
                                        _createElementVNode("li", null, [
                                            _createElementVNode("a", {
                                                href: props.onlineClassBaseUrl + '/?locale=' + props.currentUserLocale.replace('-', '_') + '&classType=STRONG+Nation',
                                                "data-ga-category": "Navigation Menu",
                                                "data-ga-action": "Classes_Find a Virtual Class",
                                                "data-ga-label": props.gaLabel,
                                                "data-ga4-event-name": "navigation_menu",
                                                "data-ga4-action": "Classes_Find a Virtual Class",
                                                "data-ga4-details": props.gaLabel
                                            }, _toDisplayString(props.t.findAVirtualClassText), 9, _hoisted_20)
                                        ])
                                    ], 64)),
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: '/' + props.currentUserLocale + '/instructor',
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "About_Find an Instructor",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "navigation_menu",
                                        "data-ga4-action": "About_Find an Instructor",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.findAnInstructorText), 9, _hoisted_21)
                                ]),
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: '/' + props.currentUserLocale + '/become-an-instructor',
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "About_Become an Instructor",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "navigation_menu",
                                        "data-ga4-action": "About_Become an Instructor",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.becomeAnInstructorText), 9, _hoisted_22)
                                ])
                            ])
                        ]),
                        _createElementVNode("li", _hoisted_23, [
                            _createElementVNode("a", {
                                class: "gh-main-nav-link",
                                href: props.instructorTrainingsLink,
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Instructor Trainings",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Instructor Trainings",
                                "data-ga4-details": props.gaLabel
                            }, _toDisplayString(props.t.instructorTrainingsText), 9, _hoisted_24),
                            _createElementVNode("ul", _hoisted_25, [
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: props.instructorTrainingsLink,
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Instructor Trainings_Become an Instructor",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "navigation_menu",
                                        "data-ga4-action": "Instructor Trainings_Become an Instructor",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.becomeAnInstructorText), 9, _hoisted_26)
                                ]),
                                (props.isStrongVirtualHeaderMenuEnabled && !props.isTrainingStrongRegistrationFlowEnabled)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_27, [
                                        _createElementVNode("a", {
                                            href: '/' + props.currentUserLocale + '/become-an-instructor-virtual',
                                            "data-ga-category": "Navigation Menu",
                                            "data-ga-action": "Instructor Trainings_View Virtual Trainings",
                                            "data-ga-label": props.gaLabel,
                                            "data-ga4-event-name": "navigation_menu",
                                            "data-ga4-action": "Instructor Trainings_View Virtual Trainings",
                                            "data-ga4-details": props.gaLabel
                                        }, _toDisplayString(props.t.virtualTrainingsText), 9, _hoisted_28)
                                    ]))
                                    : _createCommentVNode("", true),
                                _createElementVNode("li", null, [
                                    (props.isStrongVirtualHeaderMenuEnabled)
                                        ? (_openBlock(), _createElementBlock("a", {
                                            key: 0,
                                            href: '/' + props.currentUserLocale + '/training/nearby',
                                            "data-ga-category": "Navigation Menu",
                                            "data-ga-action": "Instructor Trainings_View Find_A_Training Trainings",
                                            "data-ga-label": props.gaLabel,
                                            "data-ga4-event-name": "navigation_menu",
                                            "data-ga4-action": "Instructor Trainings_View Find_A_Training Trainings",
                                            "data-ga4-details": props.gaLabel
                                        }, _toDisplayString(props.t.findATrainingText), 9, _hoisted_29))
                                        : (_openBlock(), _createElementBlock("a", {
                                            key: 1,
                                            href: '/' + props.currentUserLocale + '/training/nearby',
                                            "data-ga-category": "Navigation Menu",
                                            "data-ga-action": "Instructor Trainings_View All Trainings",
                                            "data-ga-label": props.gaLabel,
                                            "data-ga4-event-name": "navigation_menu",
                                            "data-ga4-action": "Shop",
                                            "data-ga4-details": props.gaLabel
                                        }, _toDisplayString(props.t.viewAllTrainingsText), 9, _hoisted_30))
                                ]),
                                (props.isZjRefactorNavigationEnabled && props.isAuthenticated)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_31, [
                                        _createElementVNode("a", {
                                            href: '/' + props.currentUserLocale + '/sync_labs',
                                            "data-ga-category": "Navigation Menu",
                                            "data-ga-action": "Instructors_SYNC Labs",
                                            "data-ga-label": props.gaLabel,
                                            "data-ga4-event-name": "navigation_menu",
                                            "data-ga4-action": "Instructor Trainings_View All Trainings",
                                            "data-ga4-details": props.gaLabel
                                        }, _toDisplayString(props.t.syncLabsText), 9, _hoisted_32)
                                    ]))
                                    : _createCommentVNode("", true)
                            ])
                        ]),
                        _createElementVNode("li", _hoisted_33, [
                            _createElementVNode("a", {
                                class: "gh-main-nav-link",
                                href: props.smtPublicCeaseStrongShopRedirection ? props.shopUrl : props.shopUrlWithRedirection,
                                title: props.t.shopText,
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Shop",
                                "data-ga-label": props.gaLabel,
                                "data-ga4-event-name": "navigation_menu",
                                "data-ga4-action": "Shop",
                                "data-ga4-details": props.gaLabel,
                                target: "_blank",
                                rel: "noopener"
                            }, _toDisplayString(props.t.shopText), 9, _hoisted_34)
                        ])
                    ], 2)
                ])
            ], 2));
        };
    }
});
