import { createApp, defineComponent } from 'vue/dist/vue.esm-bundler';
import SHeader from '@modules/Layout/Strong/SHeader.vue';
import MenuButton from "@modules/Layout/nav/header/hamburger/Zumba/MenuButton.vue";
const HeaderComponent = defineComponent({
    name: 'HeaderComponent',
    components: {
        MenuButton,
        SHeader
    }
});
createApp(HeaderComponent).mount('#header-component');
