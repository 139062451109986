import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = { class: "ocn-list-item" };
const _hoisted_2 = ["title"];
const _hoisted_3 = { class: "ocn-list-item" };
const _hoisted_4 = ["href", "title", "data-ga-label"];
const _hoisted_5 = { class: "ocn-list-item" };
const _hoisted_6 = ["href", "title", "data-ga-label"];
const _hoisted_7 = { class: "ocn-list-item" };
const _hoisted_8 = ["href", "title", "data-ga-label"];
const _hoisted_9 = { class: "ocn-list-item" };
const _hoisted_10 = ["href", "title", "data-ga-label"];
const _hoisted_11 = { class: "ocn-list-item" };
const _hoisted_12 = ["href", "title", "data-ga-label"];
const _hoisted_13 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_14 = ["href", "data-ga-label"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'About',
    props: {
        active: { type: Object, default() { return {}; } },
        t: {
            type: Object,
            default: () => ({
                aboutText: 'About',
                aboutTheClassText: 'About the Class',
                ourStoryText: 'Our Story',
                resultsText: 'Results',
                forGymsText: 'For Gyms',
                memberBenefitsText: 'Member Benefits',
                hireAnInstructorText: 'Hire an Instructor',
                postAJobText: 'Post A Job',
            })
        },
        currentUserLocale: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        requestQueryCat: { type: String, default: '' },
        isJobBoardFormEnabled: { type: String, default: '' },
        isGymPostAJobEnabled: { type: Boolean, default: false },
    },
    setup(__props) {
        const isEmpty = (obj) => Object.keys(obj ?? {}).length == 0;
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("li", _hoisted_1, [
                _createElementVNode("a", {
                    class: "more-below ocn-list-link",
                    href: "#",
                    title: __props.t.aboutText
                }, _toDisplayString(__props.t.aboutText), 9, _hoisted_2),
                _createElementVNode("ul", {
                    class: "ocn-subnav",
                    style: _normalizeStyle(!isEmpty(props.active?.['About']) ? 'display: block;' : '')
                }, [
                    _createElementVNode("li", _hoisted_3, [
                        _createElementVNode("a", {
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': (props.active?.['About']?.['/party'] && props.requestQueryCat) }]),
                            href: '/' + props.currentUserLocale + '/party',
                            title: __props.t.aboutTheClassText,
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Classes_About the Class",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(__props.t.aboutTheClassText), 11, _hoisted_4)
                    ]),
                    _createElementVNode("li", _hoisted_5, [
                        _createElementVNode("a", {
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['About']?.['/about'] }]),
                            href: '/' + props.currentUserLocale + '/about',
                            title: __props.t.ourStoryText,
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "The Story of Strong",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(__props.t.ourStoryText), 11, _hoisted_6)
                    ]),
                    _createElementVNode("li", _hoisted_7, [
                        _createElementVNode("a", {
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['About']?.['/member-benefits'] }]),
                            href: '/' + props.currentUserLocale + '/member-benefits',
                            title: __props.t.memberBenefitsText,
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Member Benefits",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(__props.t.memberBenefitsText), 11, _hoisted_8)
                    ]),
                    _createElementVNode("li", _hoisted_9, [
                        _createElementVNode("a", {
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['About']?.['/results'] }]),
                            href: '/' + props.currentUserLocale + '/results',
                            title: __props.t.resultsText,
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Strong Results",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(__props.t.resultsText), 11, _hoisted_10)
                    ]),
                    _createElementVNode("li", _hoisted_11, [
                        _createElementVNode("a", {
                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['About']?.['/clubs'] }]),
                            href: '/' + props.currentUserLocale + '/clubs',
                            title: __props.t.forGymsText,
                            "data-ga-category": "Navigation Menu",
                            "data-ga-action": "Strong For Gyms",
                            "data-ga-label": props.gaLabel
                        }, _toDisplayString(__props.t.forGymsText), 11, _hoisted_12)
                    ]),
                    (props.isJobBoardFormEnabled)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                            _createElementVNode("a", {
                                href: '/' + props.currentUserLocale + '/hireinstructor' + (props.isGymPostAJobEnabled ? '#job-board-form-section' : ''),
                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['About']?.['/hireinstructor'] }]),
                                "data-ga-category": "Navigation Menu",
                                "data-ga-action": "Hire an Instructor",
                                "data-ga-label": props.gaLabel
                            }, _toDisplayString(props.isGymPostAJobEnabled ? props.t.postAJobText : props.t.hireAnInstructorText), 11, _hoisted_14)
                        ]))
                        : _createCommentVNode("", true)
                ], 4)
            ]));
        };
    }
});
