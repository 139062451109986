import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, unref as _unref, renderSlot as _renderSlot, Fragment as _Fragment, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_2 = ["href", "data-ga-label"];
const _hoisted_3 = ["href", "data-ga-label"];
const _hoisted_4 = ["href", "data-ga-label"];
const _hoisted_5 = { class: "ocn-list-item" };
const _hoisted_6 = ["title"];
const _hoisted_7 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_8 = ["href", "title"];
const _hoisted_9 = {
    key: 1,
    class: "ocn-list-item"
};
const _hoisted_10 = ["href", "data-ga-label"];
const _hoisted_11 = { key: 2 };
const _hoisted_12 = { class: "ocn-list-item__no-access ocn-list-link" };
const _hoisted_13 = {
    key: 3,
    class: "ocn-list-item"
};
const _hoisted_14 = ["href"];
const _hoisted_15 = { class: "new-feature-item" };
const _hoisted_16 = { class: "new-feature-tag-wrap" };
const _hoisted_17 = { class: "ocn-list-item" };
const _hoisted_18 = ["href", "data-ga-label"];
const _hoisted_19 = { class: "ocn-list-item" };
const _hoisted_20 = ["href", "data-ga-label"];
const _hoisted_21 = { class: "ocn-list-item" };
const _hoisted_22 = ["href", "data-ga-label"];
const _hoisted_23 = { class: "ocn-list-item" };
const _hoisted_24 = ["href", "data-ga-label"];
const _hoisted_25 = { class: "ocn-list-item" };
const _hoisted_26 = ["href", "data-ga-label"];
const _hoisted_27 = {
    key: 4,
    class: "ocn-list-item"
};
const _hoisted_28 = ["href", "data-ga-label"];
const _hoisted_29 = { key: 5 };
const _hoisted_30 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_31 = {
    key: 6,
    class: "ocn-list-item"
};
const _hoisted_32 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_33 = ["href", "data-ga-label"];
const _hoisted_34 = {
    key: 7,
    class: "ocn-list-item"
};
const _hoisted_35 = ["href", "data-ga-label"];
const _hoisted_36 = { class: "ocn-list-item" };
const _hoisted_37 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_38 = ["href"];
const _hoisted_39 = {
    key: 1,
    class: "ocn-list-item"
};
const _hoisted_40 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_41 = ["href"];
const _hoisted_42 = {
    key: 2,
    class: "ocn-list-item"
};
const _hoisted_43 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_44 = ["href", "data-ga-label"];
const _hoisted_45 = { class: "ocn-list-item" };
const _hoisted_46 = { class: "ocn-list-item" };
const _hoisted_47 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_48 = ["href", "data-ga-label"];
const _hoisted_49 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_50 = ["href", "data-ga-action"];
const _hoisted_51 = {
    key: 0,
    class: "new-feature-item"
};
const _hoisted_52 = {
    key: 1,
    class: "new-feature-tag-wrap"
};
const _hoisted_53 = { key: 2 };
const _hoisted_54 = {
    key: 1,
    class: "ocn-list-item"
};
const _hoisted_55 = ["href", "data-ga-label"];
const _hoisted_56 = {
    key: 2,
    class: "ocn-list-item"
};
const _hoisted_57 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_58 = ["href", "data-ga-label"];
const _hoisted_59 = {
    key: 3,
    class: "ocn-list-item"
};
const _hoisted_60 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_61 = { class: "ocn-list-item" };
const _hoisted_62 = { class: "ocn-list-item" };
const _hoisted_63 = ["innerHTML"];
const _hoisted_64 = { key: 1 };
const _hoisted_65 = ["href", "data-ga-label", "innerHTML"];
const _hoisted_66 = ["href", "data-ga-label", "innerHTML"];
const _hoisted_67 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_68 = ["href", "data-ga-label"];
const _hoisted_69 = {
    key: 1,
    class: "ocn-list-item"
};
const _hoisted_70 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_71 = ["href"];
const _hoisted_72 = { class: "ocn-list-item" };
const _hoisted_73 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_74 = ["href", "data-ga-label", "innerHTML"];
const _hoisted_75 = { class: "ocn-list-item" };
const _hoisted_76 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_77 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_78 = ["href", "data-ga-label"];
const _hoisted_79 = {
    key: 1,
    class: "ocn-list-item"
};
const _hoisted_80 = ["href", "data-ga-label"];
const _hoisted_81 = {
    key: 2,
    class: "ocn-list-item"
};
const _hoisted_82 = {
    key: 0,
    class: "ocn-list-item__no-access ocn-list-link"
};
const _hoisted_83 = ["href", "data-ga-label"];
const _hoisted_84 = {
    key: 8,
    class: "ocn-list-item"
};
const _hoisted_85 = ["href"];
const _hoisted_86 = { class: "ocn-list-item" };
const _hoisted_87 = ["href", "data-ga-label"];
const _hoisted_88 = {
    key: 0,
    class: "ocn-list-item"
};
const _hoisted_89 = ["href", "data-ga-category", "data-ga-label"];
const _hoisted_90 = {
    key: 1,
    class: "ocn-list-item"
};
const _hoisted_91 = ["href", "data-ga-label"];
const _hoisted_92 = {
    key: 2,
    class: "ocn-list-item"
};
const _hoisted_93 = ["href", "data-ga-label"];
const _hoisted_94 = { class: "ocn-list-item" };
const _hoisted_95 = ["href", "data-ga-label"];
const _hoisted_96 = {
    key: 3,
    class: "ocn-list-item"
};
const _hoisted_97 = ["href", "data-ga-label"];
const _hoisted_98 = {
    key: 4,
    class: "ocn-list-item"
};
const _hoisted_99 = ["href", "data-ga-label"];
const _hoisted_100 = {
    key: 9,
    class: "ocn-list-item"
};
const _hoisted_101 = ["href"];
const _hoisted_102 = { class: "ocn-list-item" };
const _hoisted_103 = ["href", "data-ga-label"];
const _hoisted_104 = ["href", "data-ga-label"];
const _hoisted_105 = { class: "ocn-list-item" };
const _hoisted_106 = ["href", "data-ga-label"];
const _hoisted_107 = {
    key: 1,
    class: "ocn-list-item"
};
const _hoisted_108 = ["href", "innerHTML"];
import { track } from '@ts/Util/analytics';
import { computed } from 'vue';
import OnHold from '../Zumba/OnHold.vue';
import Classes from './Classes.vue';
import Shop from './Shop.vue';
import About from './About.vue';
import Instructors from './Instructors.vue';
import Sessions from './Sessions.vue';
import Benefits from './Benefits.vue';
import Tag from '@components/Tags/Tag.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'UserMenu',
    props: {
        active: { type: Object, default: () => { } },
        t: {
            type: Object,
            default: () => ({
                aboutText: 'About',
                aboutTheClassText: 'About the Class',
                accountOnHoldText: 'Account on Hold',
                becomeAnInstructorText: 'Become an Instructor',
                circlNewTagText: 'New',
                classesText: 'Classes',
                discountsText: 'Discounts',
                eLearningText: 'E-Learning',
                findAClassText: 'Find a Class',
                findATrainingText: 'Find a Training',
                findAVirtualClassText: 'Find a Virtual Class',
                findAnInPersonClassText: 'Find An In-Person Class',
                findAnInstructorText: 'Find an Instructor',
                forGymsText: 'For Gyms',
                forumText: 'Forum',
                globalShopText: 'Shop Clothing',
                instructorPageText: 'Instructor Page',
                instructorTrainingStatusText: 'Instructor Training Status',
                instructorsText: 'Instructors',
                manageMyAccountText: 'Manage My Account',
                manageSyncLabsText: 'Manage SYNC Labs',
                marketingMaterialsText: 'Marketing Materials',
                memberBenefitsText: 'Member Benefits',
                memberToolsText: 'Member Tools',
                myAccountText: 'My Account',
                myDashboardText: 'My Dashboard',
                syncHomeText: 'SYNC Home',
                myTrainingsText: 'My Trainings',
                onHoldEndStatusText: 'Your account is currently on hold',
                ourStoryText: 'Our Story',
                postYourLivestreamText: 'Post Your Livestream',
                resultsText: 'Results',
                sessionsSyncLabsText: 'SYNC Labs',
                shopText: 'Shop Clothing',
                strongForGymsText: 'STRONG for Gyms',
                strongNowText: 'STRONG Now',
                syncGoAppText: 'SYNC Go App',
                classBuzzAppText: 'Class Buzz App',
                syncLabsText: 'SYNC Labs',
                syncSupportText: 'SYNC Support',
                tippingText: 'Tipping',
                trainingAttendanceText: 'Training Attendance',
                trainingLocationManagementText: 'Training Location Management',
                trainingMaterialRequestText: 'Training Material Request',
                trainingRegistrationText: 'Training Registration',
                trainingRequestFormText: 'Training Request Form',
                updateOnHoldDateText: 'Update on Hold Date',
                updatePaymentText: 'Update Payment',
                userMenuMyTrainingsText: 'My Trainings',
                viewAllTrainingsText: 'View All Trainings',
                virtualTrainingsText: 'Virtual Trainings',
                masterTrainerLinksText: 'Master Trainer Links',
                masterTrainerDocsText: 'Master Trainer Docs',
                classManagement: 'Class Management',
                newFeature: 'New',
                myClasses: 'My Classes',
                classPacks: 'Class Packs',
                students: 'Students',
                settings: 'Settings',
                instructorTrainingsText: 'Instructor Trainings',
                referFriendText: 'Refer A Friend',
                leadershipOpportunitiesText: 'Leadership Opportunities',
                teachingPlusPromotionText: 'Teaching + Promotion',
                trainingsPlusEducationText: 'Trainings + Education',
                earningsText: 'Earnings + Support',
                communityText: 'Community + Support',
                communityChampionsText: 'SYNC Community Champions',
                noomCoachingText: 'Noom Coaching',
                statusCardText: 'Membership Status Card',
            })
        },
        canAccessEducationSpecialistDocuments: { type: String, default: '' },
        canAccessEducationSpecialistMenu: { type: String, default: '' },
        canAccessMemberMenu: { type: String, default: '' },
        canAccessDcd: { type: String, default: '' },
        canManageGymProfile: { type: String, default: '' },
        canManageTrainingLocations: { type: String, default: '' },
        canRequestTrainingMaterials: { type: String, default: '' },
        canRequestTrainings: { type: String, default: '' },
        currentUserLocale: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        hasPendingFirstTraining: { type: String, default: '' },
        isDgfPublicEnabled: { type: String, default: '' },
        isSyncgoAppLinksEnabled: { type: String, default: '' },
        isClassbuzzAppLinksEnabled: { type: String, default: '' },
        hasTechnicalSessionLink: { type: String, default: '' },
        isGuestUser: { type: String, default: '' },
        isMembershipCirclInstructorNavigationEnabled: { type: String, default: '' },
        isMembershipCirclInstructorNavigationNewEnabled: { type: String, default: '' },
        isMembershipTypeZumba: { type: String, default: '' },
        isTippingLandingLinkLeftMenuEnabled: { type: String, default: '' },
        isSstudPublicSyncStudioLinkLeftMenuEnabled: { type: String, default: '' },
        manageClassesAction: { type: String, default: '' },
        manageClassesActive: { type: String, default: '' },
        manageClassesText: { type: String, default: '' },
        memberProfileEnabled: { type: String, default: '' },
        userHasBenefitCreateOnlineClass: { type: String, default: '' },
        userHasBenefitInstructorProfile: { type: String, default: '' },
        userHasBenefitStrongForumAccess: { type: String, default: '' },
        userHasBenefitZinJammerSessions: { type: String, default: '' },
        userHasBenefitMemberPortal: { type: String, default: '' },
        userHasNoomCertificationAccess: { type: String, default: '' },
        userHasNoomBenefit: { type: String, default: '' },
        userHasBenefitMembershipStatusCard: { type: String, default: '' },
        isAuthenticated: { type: String, default: '' },
        isOnHold: { type: String, default: '' },
        onHoldEnd: { type: String, default: '' },
        zinProfileAccountLink: { type: String, default: '' },
        isZjRefactorDashboardStrongEnabled: { type: String, default: '' },
        profileUrlParams: { type: String, default: '' },
        isStrongResultsMenuEnabled: { type: String, default: '' },
        onlineClassBaseUrl: { type: String, default: '' },
        requestQueryCat: { type: String, default: '' },
        isZjRefactorNavigationStrongEnabled: { type: String, default: '' },
        isStrongVirtualHeaderMenuEnabled: { type: String, default: '' },
        hasPreMembershipLinksOnly: { type: String, default: '' },
        navigationShopUrl: { type: String, default: '' },
        isStrongShopSubnavEnabled: { type: String, default: '' },
        shouldShowJoinLink: { type: String, default: '' },
        membershipTypeSlug: { type: String, default: '' },
        classManagementEnabled: { type: Boolean, default: false },
        classLocatorEnabled: { type: String, default: '' },
        isCirclUrl: { type: String, default: '' },
        isNoomCertificationEnabled: { type: String, default: '' },
        isBenefitMembershipStatusCardEnabled: { type: String, default: '' },
        restrictedReferFriend: { type: Boolean, default: false },
        restrictedInstructorTraining: { type: Boolean, default: false },
        restrictedLeadershipOpportunities: { type: Boolean, default: false },
        newReferralProgram: { type: Boolean, default: false },
        userHasDelinquentMembership: { type: String, default: '' },
        isPrePaymentAccessEnabled: { type: String, default: '' },
        showShareAndEarn: { type: Boolean, default: false },
        isJobBoardFormEnabled: { type: String, default: '' },
        isVanillaCommunityDisabled: { type: String, default: '' },
        hideJapaneseMenu: { type: Boolean, default: false },
        isGymPostAJobEnabled: { type: Boolean, default: false },
    },
    setup(__props) {
        const props = __props;
        const showNoom = computed(() => props.isNoomCertificationEnabled && props.userHasNoomCertificationAccess && !props.hideJapaneseMenu);
        const openTools = computed(() => {
            const currentUrl = window.location.pathname;
            return props.classManagementEnabled && (currentUrl.startsWith('/' + props.currentUserLocale + '/dashboard'));
        });
        const openClassManagement = computed(() => {
            const currentUrl = window.location.pathname;
            return props.classManagementEnabled && (currentUrl.startsWith('/' + props.currentUserLocale + '/member/class_management') ||
                currentUrl.startsWith('/' + props.currentUserLocale + '/member/instructor_page/') ||
                currentUrl.startsWith('/' + props.currentUserLocale + '/member/packages'));
        });
        const openNestedSubmenu = computed(() => {
            return props.active?.['Teach'] || props.active?.['Learn'] || props.active?.['Earn'] || props.active?.['Connect'];
        });
        const isKeyEmpty = (key) => Object.keys(key ?? {}).length == 0;
        const studentDashboardText = computed(() => props.userHasBenefitMemberPortal ? props.t.activityAsAStudentText : props.t.activityText);
        const communityLink = computed(() => props.isVanillaCommunityDisabled ? 'https://www.zumba.community/STRONG_Nation_Community' : 'https://strong.zumba.community');
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                (props.isAuthenticated)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        ((!props.canManageGymProfile && !props.isGuestUser))
                            ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
                                (props.canAccessMemberMenu)
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 0,
                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['/dashboard']) }]),
                                        href: '/' + props.currentUserLocale + '/dashboard',
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "My SYNC Home",
                                        "data-ga-label": props.gaLabel
                                    }, _toDisplayString(props.t.syncHomeText), 11, _hoisted_2))
                                    : (_openBlock(), _createElementBlock("a", {
                                        key: 1,
                                        class: "ocn-list-link",
                                        href: '/' + props.currentUserLocale + '/dashboard',
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "My Dashboard",
                                        "data-ga-label": props.gaLabel
                                    }, _toDisplayString(props.t.myDashboardText), 9, _hoisted_3)),
                                (props.hasPendingFirstTraining)
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 2,
                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['MemberTools']?.['/training/history'] }]),
                                        href: '/' + props.currentUserLocale + '/training/history',
                                        "data-ga-category": "Navigation Menu",
                                        "data-ga-action": "Training History",
                                        "data-ga-label": props.gaLabel
                                    }, _toDisplayString(props.t.myTrainingsText), 11, _hoisted_4))
                                    : _createCommentVNode("", true)
                            ]))
                            : _createCommentVNode("", true),
                        _createElementVNode("li", _hoisted_5, [
                            _createElementVNode("a", {
                                class: _normalizeClass(["more-below ocn-list-link", {
                                        'js-active-item inactive-item': !isKeyEmpty(props.active?.['Tools']) || openTools.value || openClassManagement.value || openNestedSubmenu.value,
                                    }]),
                                href: "#",
                                title: props.t.memberToolsText
                            }, _toDisplayString(props.t.memberToolsText), 11, _hoisted_6),
                            _createElementVNode("ul", {
                                class: "ocn-subnav",
                                style: _normalizeStyle((!isKeyEmpty(props.active?.['Tools']) || openTools.value || openClassManagement.value || openNestedSubmenu.value) ? 'display: block;' : '')
                            }, [
                                ((props.isBenefitMembershipStatusCardEnabled && props.userHasBenefitMembershipStatusCard))
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                                        _createElementVNode("a", {
                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/membership/status_card' + props.membershipTypeSlug.toLowerCase()] }]),
                                            href: '/' + props.currentUserLocale + '/membership/status_card/' + props.membershipTypeSlug.toLowerCase(),
                                            title: props.t.statusCardText
                                        }, _toDisplayString(props.t.statusCardText), 11, _hoisted_8)
                                    ]))
                                    : _createCommentVNode("", true),
                                (props.canAccessDcd)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
                                        _createElementVNode("a", {
                                            id: "hamburger-link-learn-zin-media",
                                            href: '/' + props.currentUserLocale + '/now',
                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['Learn']?.['/now']) }]),
                                            "data-ga-category": "Navigation Menu",
                                            "data-ga-action": "SYNC Now",
                                            "data-ga-label": props.gaLabel
                                        }, _toDisplayString(props.t.strongNowText), 11, _hoisted_10)
                                    ]))
                                    : _createCommentVNode("", true),
                                (props.hasPreMembershipLinksOnly)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_11, [
                                        _createElementVNode("span", _hoisted_12, _toDisplayString(props.t.classManagement), 1)
                                    ]))
                                    : (props.canAccessMemberMenu && props.classManagementEnabled)
                                        ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                                            _createElementVNode("a", {
                                                href: '/' + props.currentUserLocale + '/member/class_management',
                                                class: _normalizeClass(["ocn-list-link more-below with-new-feature-badge", {
                                                        'js-active-item inactive-item': !isKeyEmpty(props.active?.['ClassManagement']) || openClassManagement.value,
                                                        'pre-selected-active': !isKeyEmpty(props.active?.['ClassManagement']) || openClassManagement.value
                                                    }])
                                            }, [
                                                _createElementVNode("span", _hoisted_15, _toDisplayString(props.t.classManagement), 1),
                                                _createElementVNode("span", _hoisted_16, [
                                                    _createVNode(Tag, {
                                                        variety: "small featured",
                                                        "show-cancel": false,
                                                        class: "new-feature-tag"
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(props.t.newFeature), 1)
                                                        ]),
                                                        _: 1
                                                    })
                                                ])
                                            ], 10, _hoisted_14),
                                            _createElementVNode("ul", {
                                                class: "ocn-subnav",
                                                style: _normalizeStyle(!isKeyEmpty(props.active?.['ClassManagement']) ||
                                                    openClassManagement.value ? 'display: block;' : '')
                                            }, [
                                                _createElementVNode("li", _hoisted_17, [
                                                    _createElementVNode("a", {
                                                        href: '/' + props.currentUserLocale + '/member/class_management',
                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['ClassManagement']?.['/member/class_management'] }]),
                                                        "data-ga-category": "Class Management",
                                                        "data-ga-action": "My Classes",
                                                        "data-ga-label": props.gaLabel
                                                    }, _toDisplayString(props.t.myClasses), 11, _hoisted_18)
                                                ]),
                                                _createElementVNode("li", _hoisted_19, [
                                                    _createElementVNode("a", {
                                                        href: '/' + props.currentUserLocale + '/member/instructor_page/view',
                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['ClassManagement']?.['/member/instructor_page/view'] }]),
                                                        "data-ga-category": "Class Management",
                                                        "data-ga-action": "Instructor Page",
                                                        "data-ga-label": props.gaLabel
                                                    }, _toDisplayString(props.t.instructorPageText), 11, _hoisted_20)
                                                ]),
                                                _createElementVNode("li", _hoisted_21, [
                                                    _createElementVNode("a", {
                                                        href: '/' + props.currentUserLocale + '/member/packages',
                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['ClassManagement']?.['/member/packages'] }]),
                                                        "data-ga-category": "Class Management",
                                                        "data-ga-action": "Class Packs",
                                                        "data-ga-label": props.gaLabel
                                                    }, _toDisplayString(props.t.classPacks), 11, _hoisted_22)
                                                ]),
                                                _createElementVNode("li", _hoisted_23, [
                                                    _createElementVNode("a", {
                                                        href: '/' + props.currentUserLocale + '/member/class_management/students',
                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['ClassManagement']?.['/member/class_management/students'] }]),
                                                        "data-ga-category": "Class Management",
                                                        "data-ga-action": "Students",
                                                        "data-ga-label": props.gaLabel
                                                    }, _toDisplayString(props.t.students), 11, _hoisted_24)
                                                ]),
                                                _createElementVNode("li", _hoisted_25, [
                                                    _createElementVNode("a", {
                                                        href: '/' + props.currentUserLocale + '/member/class_management/settings',
                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['ClassManagement']?.['/member/class_management/settings'] }]),
                                                        "data-ga-category": "Class Management",
                                                        "data-ga-action": "Settings",
                                                        "data-ga-label": props.gaLabel
                                                    }, _toDisplayString(props.t.settings), 11, _hoisted_26)
                                                ])
                                            ], 4)
                                        ]))
                                        : _createCommentVNode("", true),
                                (props.canAccessMemberMenu && !props.classManagementEnabled)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_27, [
                                        _createElementVNode("a", {
                                            href: props.manageClassesAction,
                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.manageClassesActive }]),
                                            "data-ga-category": "Navigation Menu",
                                            "data-ga-action": "Tools_My Classes",
                                            "data-ga-label": props.gaLabel
                                        }, _toDisplayString(props.t.manageClassesText), 11, _hoisted_28)
                                    ]))
                                    : _createCommentVNode("", true),
                                (__props.hasPreMembershipLinksOnly)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_29, [
                                        (props.hasPreMembershipLinksOnly)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(props.t.postYourLivestreamText), 1))
                                            : _createCommentVNode("", true)
                                    ]))
                                    : _createCommentVNode("", true),
                                (props.memberProfileEnabled && props.userHasBenefitInstructorProfile)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_31, [
                                        (props.hasPreMembershipLinksOnly)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(props.t.instructorPageText), 1))
                                            : (_openBlock(), _createElementBlock("a", {
                                                key: 1,
                                                href: '/' + props.currentUserLocale + '/member/profile',
                                                class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['MemberTools']?.['/instructor/' + props.profileUrlParams]) }]),
                                                "data-ga-category": "Navigation Menu",
                                                "data-ga-action": "Member Tools_My Profile",
                                                "data-ga-label": props.gaLabel
                                            }, _toDisplayString(props.t.instructorPageText), 11, _hoisted_33))
                                    ]))
                                    : _createCommentVNode("", true),
                                (props.isAuthenticated)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_34, [
                                        _createElementVNode("a", {
                                            href: '/' + props.currentUserLocale + '/training/history',
                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['MemberTools']?.['/training/history']) }]),
                                            "data-ga-category": "Navigation Menu",
                                            "data-ga-action": "Member Tools_My Trainings",
                                            "data-ga-label": props.gaLabel
                                        }, _toDisplayString(props.t.userMenuMyTrainingsText), 11, _hoisted_35)
                                    ]))
                                    : _createCommentVNode("", true),
                                _createElementVNode("li", _hoisted_36, [
                                    _createElementVNode("a", {
                                        class: _normalizeClass(["more-below ocn-list-link", {
                                                'js-active-item inactive-item': !isKeyEmpty(props.active?.['Teach']),
                                                'pre-selected-active': !isKeyEmpty(props.active?.['Teach'])
                                            }])
                                    }, _toDisplayString(props.t.teachingPlusPromotionText), 3),
                                    _createElementVNode("ul", {
                                        class: "ocn-subnav subnav-nested",
                                        style: _normalizeStyle(!isKeyEmpty(props.active?.['Teach']) ? 'display: block;' : '')
                                    }, [
                                        (props.canAccessMemberMenu && props.isSyncgoAppLinksEnabled)
                                            ? (_openBlock(), _createElementBlock("li", _hoisted_37, [
                                                _createElementVNode("a", {
                                                    class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Teach']?.['/syncgo'] }]),
                                                    href: '/' + props.currentUserLocale + '/syncgo',
                                                    "data-ga-category": "Navigation Menu",
                                                    "data-ga-action": "SYNC_Go_App"
                                                }, _toDisplayString(props.t.syncGoAppText), 11, _hoisted_38)
                                            ]))
                                            : _createCommentVNode("", true),
                                        (props.canAccessMemberMenu && props.isClassbuzzAppLinksEnabled)
                                            ? (_openBlock(), _createElementBlock("li", _hoisted_39, [
                                                (props.hasPreMembershipLinksOnly)
                                                    ? (_openBlock(), _createElementBlock("span", _hoisted_40, _toDisplayString(props.t.classBuzzAppText), 1))
                                                    : (_openBlock(), _createElementBlock("a", {
                                                        key: 1,
                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Teach']?.['/classbuzz'] }]),
                                                        href: '/' + props.currentUserLocale + '/classbuzz',
                                                        "data-ga-category": "Navigation Menu",
                                                        "data-ga-action": "Class_Buzz_App"
                                                    }, _toDisplayString(props.t.classBuzzAppText), 11, _hoisted_41))
                                            ]))
                                            : _createCommentVNode("", true),
                                        (props.canAccessMemberMenu)
                                            ? (_openBlock(), _createElementBlock("li", _hoisted_42, [
                                                (props.hasPreMembershipLinksOnly)
                                                    ? (_openBlock(), _createElementBlock("span", _hoisted_43, _toDisplayString(props.t.marketingMaterialsText), 1))
                                                    : (_openBlock(), _createElementBlock("a", {
                                                        key: 1,
                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Teach']?.['/marketing_materials'] }]),
                                                        target: "_blank",
                                                        rel: "noopener",
                                                        href: '/' + props.currentUserLocale + '/marketing_materials',
                                                        "data-ga-category": "Navigation Menu",
                                                        "data-ga-action": "Marketing Materials",
                                                        "data-ga-label": props.gaLabel,
                                                        onClick: _cache[0] || (_cache[0] = () => _unref(track)('MM: Downloads', {}))
                                                    }, _toDisplayString(props.t.marketingMaterialsText), 11, _hoisted_44))
                                            ]))
                                            : _createCommentVNode("", true)
                                    ], 4)
                                ]),
                                _createElementVNode("li", _hoisted_45, [
                                    _createElementVNode("a", {
                                        class: _normalizeClass(["more-below ocn-list-link", {
                                                'js-active-item inactive-item': !isKeyEmpty(props.active?.['Learn']),
                                                'pre-selected-active': !isKeyEmpty(props.active?.['Learn'])
                                            }])
                                    }, _toDisplayString(props.t.trainingsPlusEducationText), 3),
                                    _createElementVNode("ul", {
                                        class: "ocn-subnav",
                                        style: _normalizeStyle(!isKeyEmpty(props.active?.['Learn']) ? 'display: block;' : '')
                                    }, [
                                        _createElementVNode("li", _hoisted_46, [
                                            (props.hasPreMembershipLinksOnly && props.restrictedInstructorTraining)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_47, _toDisplayString(props.t.instructorTrainingsText), 1))
                                                : (_openBlock(), _createElementBlock("a", {
                                                    key: 1,
                                                    href: '/' + props.currentUserLocale + '/training',
                                                    class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Learn']?.['/training'] }]),
                                                    "data-ga-category": "Navigation Menu",
                                                    "data-ga-action": "Trainings Education_Instructor Trainings",
                                                    "data-ga-label": props.gaLabel
                                                }, _toDisplayString(props.t.instructorTrainingsText), 11, _hoisted_48))
                                        ]),
                                        (props.isMembershipCirclInstructorNavigationEnabled)
                                            ? (_openBlock(), _createElementBlock("li", _hoisted_49, [
                                                _createElementVNode("a", {
                                                    class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Learn']?.['/circl-mobility-instructor'] }]),
                                                    href: '/' + props.currentUserLocale + '/circl-mobility-instructor',
                                                    "data-ga-category": "ZUMBA Home",
                                                    "data-ga-action": `${_ctx.$props.isMembershipTypeZumba ? 'CIRCL_Mobility_ZIN' : 'CIRCL_Mobility_SYNC'}`
                                                }, [
                                                    (props.isMembershipCirclInstructorNavigationNewEnabled && !props.isNoomCertificationEnabled)
                                                        ? (_openBlock(), _createElementBlock("span", _hoisted_51, " CIRCL Mobility™ "))
                                                        : _createCommentVNode("", true),
                                                    (props.isMembershipCirclInstructorNavigationNewEnabled && !props.isNoomCertificationEnabled)
                                                        ? (_openBlock(), _createElementBlock("span", _hoisted_52, [
                                                            _createVNode(Tag, {
                                                                variety: "small featured",
                                                                "show-cancel": false,
                                                                class: "new-feature-tag"
                                                            }, {
                                                                default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(props.t.newFeature), 1)
                                                                ]),
                                                                _: 1
                                                            })
                                                        ]))
                                                        : _createCommentVNode("", true),
                                                    (!props.isMembershipCirclInstructorNavigationNewEnabled || props.isNoomCertificationEnabled)
                                                        ? (_openBlock(), _createElementBlock("span", _hoisted_53, " CIRCL Mobility™ "))
                                                        : _createCommentVNode("", true)
                                                ], 10, _hoisted_50)
                                            ]))
                                            : _createCommentVNode("", true),
                                        (props.isAuthenticated && props.isZjRefactorDashboardStrongEnabled && props.userHasBenefitZinJammerSessions)
                                            ? (_openBlock(), _createElementBlock("li", _hoisted_54, [
                                                _createElementVNode("a", {
                                                    href: '/' + props.currentUserLocale + '/sync_labs',
                                                    class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Learn']?.['/sync_labs'] }]),
                                                    "data-ga-category": "Navigation Menu",
                                                    "data-ga-action": "Instructors_SYNC Labs",
                                                    "data-ga-label": props.gaLabel
                                                }, _toDisplayString(props.t.syncLabsText), 11, _hoisted_55)
                                            ]))
                                            : _createCommentVNode("", true),
                                        (props.canAccessMemberMenu && !props.hideJapaneseMenu)
                                            ? (_openBlock(), _createElementBlock("li", _hoisted_56, [
                                                (props.hasPreMembershipLinksOnly)
                                                    ? (_openBlock(), _createElementBlock("span", _hoisted_57, _toDisplayString(props.t.eLearningText), 1))
                                                    : (_openBlock(), _createElementBlock("a", {
                                                        key: 1,
                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Learn']?.['/member/elearning'] }]),
                                                        href: '/' + props.currentUserLocale + '/member/elearning',
                                                        "data-ga-category": "Navigation Menu",
                                                        "data-ga-action": "E-Learning",
                                                        "data-ga-label": props.gaLabel
                                                    }, _toDisplayString(props.t.eLearningText), 11, _hoisted_58))
                                            ]))
                                            : _createCommentVNode("", true),
                                        (showNoom.value)
                                            ? (_openBlock(), _createElementBlock("li", _hoisted_59, [
                                                ((props.isNoomCertificationEnabled && props.hasPreMembershipLinksOnly))
                                                    ? (_openBlock(), _createElementBlock("span", _hoisted_60, _toDisplayString(props.t.noomCoachingText), 1))
                                                    : (props.isNoomCertificationEnabled)
                                                        ? _renderSlot(_ctx.$slots, "noom-link", { key: 1 })
                                                        : _createCommentVNode("", true)
                                            ]))
                                            : _createCommentVNode("", true)
                                    ], 4)
                                ]),
                                _createElementVNode("li", _hoisted_61, [
                                    _createElementVNode("a", {
                                        class: _normalizeClass(["more-below ocn-list-link", {
                                                'js-active-item inactive-item': !isKeyEmpty(props.active?.['Earn']),
                                                'pre-selected-active': !isKeyEmpty(props.active?.['Earn'])
                                            }])
                                    }, _toDisplayString(props.t.earningsText), 3),
                                    _createElementVNode("ul", {
                                        class: "ocn-subnav",
                                        style: _normalizeStyle(!isKeyEmpty(props.active?.['Earn']) ? 'display: block;' : '')
                                    }, [
                                        _createElementVNode("li", _hoisted_62, [
                                            (props.hasPreMembershipLinksOnly && props.restrictedReferFriend)
                                                ? (_openBlock(), _createElementBlock("span", {
                                                    key: 0,
                                                    class: "ocn-list-item__no-access ocn-list-link",
                                                    innerHTML: props.t.referFriendText
                                                }, null, 8, _hoisted_63))
                                                : (_openBlock(), _createElementBlock("span", _hoisted_64, [
                                                    (props.newReferralProgram && props.showShareAndEarn)
                                                        ? (_openBlock(), _createElementBlock("a", {
                                                            key: 0,
                                                            href: '/' + props.currentUserLocale + '/training/share_and_earn',
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Earn']?.['/training/share_and_earn'] }]),
                                                            "data-ga-category": "Navigation Menu",
                                                            "data-ga-action": "Refer_A_Friend",
                                                            "data-ga-label": props.gaLabel,
                                                            innerHTML: props.t.referFriendText
                                                        }, null, 10, _hoisted_65))
                                                        : _createCommentVNode("", true),
                                                    (!props.newReferralProgram)
                                                        ? (_openBlock(), _createElementBlock("a", {
                                                            key: 1,
                                                            href: '/' + props.currentUserLocale + '/training/refer',
                                                            class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Earn']?.['/training/refer'] }]),
                                                            "data-ga-category": "Navigation Menu",
                                                            "data-ga-action": "Refer_A_Friend",
                                                            "data-ga-label": props.gaLabel,
                                                            innerHTML: props.t.referFriendText
                                                        }, null, 10, _hoisted_66))
                                                        : _createCommentVNode("", true)
                                                ]))
                                        ]),
                                        (!props.hasPreMembershipLinksOnly && (props.canAccessMemberMenu &&
                                            props.isTippingLandingLinkLeftMenuEnabled &&
                                            !props.hasPreMembershipLinksOnly))
                                            ? (_openBlock(), _createElementBlock("li", _hoisted_67, [
                                                _createElementVNode("a", {
                                                    class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Earn']?.['/member/tipping'] }]),
                                                    target: "_blank",
                                                    rel: "noopener",
                                                    href: '/' + props.currentUserLocale + '/member/tipping',
                                                    "data-ga-category": "Member Home",
                                                    "data-ga-action": "Tipping",
                                                    "data-ga-label": props.gaLabel
                                                }, _toDisplayString(props.t.tippingText), 11, _hoisted_68)
                                            ]))
                                            : _createCommentVNode("", true),
                                        (props.canAccessMemberMenu)
                                            ? (_openBlock(), _createElementBlock("li", _hoisted_69, [
                                                (props.hasPreMembershipLinksOnly)
                                                    ? (_openBlock(), _createElementBlock("span", _hoisted_70, _toDisplayString(props.t.discountsText), 1))
                                                    : (_openBlock(), _createElementBlock("a", {
                                                        key: 1,
                                                        href: '/' + props.currentUserLocale + '/member/earn-discount',
                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Earn']?.['/member/earn-discount'] }])
                                                    }, _toDisplayString(props.t.discountsText), 11, _hoisted_71))
                                            ]))
                                            : _createCommentVNode("", true),
                                        _createElementVNode("li", _hoisted_72, [
                                            (props.hasPreMembershipLinksOnly && props.restrictedLeadershipOpportunities)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_73, _toDisplayString(props.t.leadershipOpportunitiesText), 1))
                                                : (_openBlock(), _createElementBlock("a", {
                                                    key: 1,
                                                    href: '/' + props.currentUserLocale + '/sync-leadership',
                                                    class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Earn']?.['/sync-leadership'] }]),
                                                    "data-ga-category": "Navigation Menu",
                                                    "data-ga-action": "Leadership_Opportunities",
                                                    "data-ga-label": props.gaLabel,
                                                    innerHTML: props.t.leadershipOpportunitiesText
                                                }, null, 10, _hoisted_74))
                                        ])
                                    ], 4)
                                ]),
                                _createElementVNode("li", _hoisted_75, [
                                    _createElementVNode("a", {
                                        class: _normalizeClass(["more-below ocn-list-link", {
                                                'js-active-item inactive-item': !isKeyEmpty(props.active?.['Connect']),
                                                'pre-selected-active': !isKeyEmpty(props.active?.['Connect'])
                                            }]),
                                        href: "#"
                                    }, _toDisplayString(props.t.communityText), 3),
                                    _createElementVNode("ul", {
                                        class: "ocn-subnav",
                                        style: _normalizeStyle(!isKeyEmpty(props.active?.['Connect']) ? 'display: block;' : '')
                                    }, [
                                        (__props.hasPreMembershipLinksOnly || (props.canAccessMemberMenu && props.userHasBenefitStrongForumAccess))
                                            ? (_openBlock(), _createElementBlock("li", _hoisted_76, [
                                                (props.hasPreMembershipLinksOnly)
                                                    ? (_openBlock(), _createElementBlock("span", _hoisted_77, _toDisplayString(props.t.forumText), 1))
                                                    : (_openBlock(), _createElementBlock("a", {
                                                        key: 1,
                                                        href: communityLink.value,
                                                        class: "ocn-list-link",
                                                        "data-ga-category": "Navigation Menu",
                                                        "data-ga-action": "Forum",
                                                        "data-ga-label": props.gaLabel
                                                    }, _toDisplayString(props.t.forumText), 9, _hoisted_78))
                                            ]))
                                            : _createCommentVNode("", true),
                                        (props.canAccessMemberMenu)
                                            ? (_openBlock(), _createElementBlock("li", _hoisted_79, [
                                                _createElementVNode("a", {
                                                    href: '/' + props.currentUserLocale + '/contact',
                                                    class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Connect']?.['/contact'] }]),
                                                    "data-ga-category": "Navigation Menu",
                                                    "data-ga-action": "SYNC Support",
                                                    "data-ga-label": props.gaLabel
                                                }, _toDisplayString(props.t.syncSupportText), 11, _hoisted_80)
                                            ]))
                                            : _createCommentVNode("", true),
                                        (props.canAccessMemberMenu)
                                            ? (_openBlock(), _createElementBlock("li", _hoisted_81, [
                                                (props.hasPreMembershipLinksOnly)
                                                    ? (_openBlock(), _createElementBlock("span", _hoisted_82, _toDisplayString(props.t.communityChampionsText), 1))
                                                    : (_openBlock(), _createElementBlock("a", {
                                                        key: 1,
                                                        href: '/' + props.currentUserLocale + '/sync-champions',
                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['Connect']?.['/sync-champions'] }]),
                                                        "data-ga-category": "Strong Home",
                                                        "data-ga-action": "Sync Champions",
                                                        "data-ga-label": props.gaLabel
                                                    }, _toDisplayString(props.t.communityChampionsText), 11, _hoisted_83))
                                            ]))
                                            : _createCommentVNode("", true)
                                    ], 4)
                                ]),
                                (props.canAccessEducationSpecialistMenu)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_84, [
                                        _createElementVNode("a", {
                                            class: _normalizeClass(["more-below ocn-list-link", { 'js-active-item inactive-item': !isKeyEmpty(props.active?.['ZES Support']) }]),
                                            href: '/' + props.currentUserLocale + '/support'
                                        }, _toDisplayString(props.t.masterTrainerLinksText), 11, _hoisted_85),
                                        _createElementVNode("ul", {
                                            class: "ocn-subnav",
                                            style: _normalizeStyle(isKeyEmpty(props.active?.['ZES Support']) ? 'display: block;' : '')
                                        }, [
                                            _createElementVNode("li", _hoisted_86, [
                                                _createElementVNode("a", {
                                                    href: '/' + props.currentUserLocale + '/es/training_status',
                                                    class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/zes/training_status']) }]),
                                                    "data-ga-category": "Navigation Menu",
                                                    "data-ga-action": "ZES Support_Instructor Training Status",
                                                    "data-ga-label": props.gaLabel
                                                }, _toDisplayString(props.t.instructorTrainingStatusText), 11, _hoisted_87)
                                            ]),
                                            (props.isDgfPublicEnabled)
                                                ? (_openBlock(), _createElementBlock("li", _hoisted_88, [
                                                    _createElementVNode("a", {
                                                        href: '/' + props.currentUserLocale + '/es/training_status/attendance',
                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/zes/training_status/attendance']) }]),
                                                        "data-ga-category": props.membershipTypeSlug + ' Home',
                                                        "data-ga-action": "ZES Support_Instructor Training Status",
                                                        "data-ga-label": props.gaLabel
                                                    }, _toDisplayString(props.t.trainingAttendanceText), 11, _hoisted_89)
                                                ]))
                                                : _createCommentVNode("", true),
                                            (props.canManageTrainingLocations)
                                                ? (_openBlock(), _createElementBlock("li", _hoisted_90, [
                                                    _createElementVNode("a", {
                                                        href: '/' + props.currentUserLocale + '/es/location/training_index',
                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/zes/location/training_index']) }]),
                                                        "data-ga-category": "Navigation Menu",
                                                        "data-ga-action": "ZES Support_Training Location Management",
                                                        "data-ga-label": props.gaLabel
                                                    }, _toDisplayString(props.t.trainingLocationManagementText), 11, _hoisted_91)
                                                ]))
                                                : _createCommentVNode("", true),
                                            (props.canRequestTrainings)
                                                ? (_openBlock(), _createElementBlock("li", _hoisted_92, [
                                                    _createElementVNode("a", {
                                                        href: '/' + props.currentUserLocale + '/es/training_request',
                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/zes/training_request']) }]),
                                                        "data-ga-category": "Navigation Menu",
                                                        "data-ga-action": "ZES Support_Training Request Form",
                                                        "data-ga-label": props.gaLabel
                                                    }, _toDisplayString(props.t.trainingRequestFormText), 11, _hoisted_93)
                                                ]))
                                                : _createCommentVNode("", true),
                                            _createElementVNode("li", _hoisted_94, [
                                                _createElementVNode("a", {
                                                    href: '/' + props.currentUserLocale + '/es/training_registration/add',
                                                    class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/zes/training_registration/add']) }]),
                                                    "data-ga-category": "Navigation Menu",
                                                    "data-ga-action": "ZES Support_Training Registration",
                                                    "data-ga-label": props.gaLabel
                                                }, _toDisplayString(props.t.trainingRegistrationText), 11, _hoisted_95)
                                            ]),
                                            (props.canAccessEducationSpecialistDocuments)
                                                ? (_openBlock(), _createElementBlock("li", _hoisted_96, [
                                                    _createElementVNode("a", {
                                                        href: '/' + props.currentUserLocale + '/es/zes_document',
                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/zes/zes_document']) }]),
                                                        "data-ga-category": "Navigation Menu",
                                                        "data-ga-action": "ZES Support_ZES Docs",
                                                        "data-ga-label": props.gaLabel
                                                    }, _toDisplayString(props.t.masterTrainerDocsText), 11, _hoisted_97)
                                                ]))
                                                : _createCommentVNode("", true),
                                            (props.canRequestTrainingMaterials)
                                                ? (_openBlock(), _createElementBlock("li", _hoisted_98, [
                                                    _createElementVNode("a", {
                                                        href: '/' + props.currentUserLocale + '/es/training_material',
                                                        class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/zes/training_material']) }]),
                                                        "data-ga-category": "Navigation Menu",
                                                        "data-ga-action": "ZES Support_Training Material Request",
                                                        "data-ga-label": props.gaLabel
                                                    }, _toDisplayString(props.t.trainingMaterialRequestText), 11, _hoisted_99)
                                                ]))
                                                : _createCommentVNode("", true)
                                        ], 4)
                                    ]))
                                    : _createCommentVNode("", true),
                                (props.canAccessMemberMenu && props.hasTechnicalSessionLink)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_100, [
                                        _createElementVNode("a", {
                                            href: '/' + props.currentUserLocale + '/trainer/session/my',
                                            class: "ocn-list-link"
                                        }, _toDisplayString(props.t.manageSyncLabsText), 9, _hoisted_101)
                                    ]))
                                    : _createCommentVNode("", true)
                            ], 4)
                        ]),
                        _createVNode(OnHold, {
                            t: props.t,
                            "current-user-locale": props.currentUserLocale,
                            "is-on-hold": props.isOnHold,
                            "on-hold-end": props.onHoldEnd
                        }, null, 8, ["t", "current-user-locale", "is-on-hold", "on-hold-end"]),
                        _createElementVNode("li", _hoisted_102, [
                            ((props.canManageGymProfile && props.canAccessMemberMenu))
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['/profile']) }]),
                                    href: '/' + props.currentUserLocale + props.zinProfileAccountLink,
                                    "data-ga-category": "Navigation Menu",
                                    "data-ga-action": "Tools_Manage My Account",
                                    "data-ga-label": props.gaLabel
                                }, _toDisplayString(props.t.manageMyAccountText), 11, _hoisted_103))
                                : (_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': !isKeyEmpty(props.active?.['/profile']) }]),
                                    href: '/' + props.currentUserLocale + '/' + props.zinProfileAccountLink,
                                    "data-ga-category": "Navigation Menu",
                                    "data-ga-action": "Tools_My Account",
                                    "data-ga-label": props.gaLabel
                                }, _toDisplayString(props.t.myAccountText), 11, _hoisted_104))
                        ]),
                        _createElementVNode("li", _hoisted_105, [
                            (props.userHasDelinquentMembership)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    class: "ocn-list-link",
                                    href: '/' + props.currentUserLocale + (props.isPrePaymentAccessEnabled ? '/membership/pay' : '/dashboard'),
                                    "data-ga-category": "Navigation Menu",
                                    "data-ga-action": "Update Payment",
                                    "data-ga-label": props.gaLabel
                                }, _toDisplayString(props.t.updatePaymentText), 9, _hoisted_106))
                                : _createCommentVNode("", true)
                        ]),
                        (props.canAccessDcd)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_107, [
                                _createElementVNode("a", {
                                    id: "hamburger-link-student-dashboard",
                                    href: '/' + props.currentUserLocale + '/student_dashboard',
                                    class: _normalizeClass(["ocn-list-link", { 'js-active-item active-item': props.active?.['/student_dashboard'] }]),
                                    "data-ga-category": "Navigation Menu",
                                    "data-ga-action": "{{studentDashboardText}}",
                                    innerHTML: studentDashboardText.value
                                }, null, 10, _hoisted_108)
                            ]))
                            : _createCommentVNode("", true)
                    ], 64))
                    : _createCommentVNode("", true),
                _createVNode(Classes, {
                    active: props.active,
                    t: props.t,
                    "current-user-locale": props.currentUserLocale,
                    "ga-label": props.gaLabel,
                    "online-class-base-url": props.onlineClassBaseUrl,
                    "request-query-cat": __props.requestQueryCat,
                    "class-locator-enabled": props.classLocatorEnabled,
                    "is-circl-url": props.isCirclUrl
                }, null, 8, ["active", "t", "current-user-locale", "ga-label", "online-class-base-url", "request-query-cat", "class-locator-enabled", "is-circl-url"]),
                _createVNode(Instructors, {
                    active: props.active,
                    t: props.t,
                    "is-strong-virtual-header-menu-enabled": props.isStrongVirtualHeaderMenuEnabled,
                    "current-user-locale": props.currentUserLocale,
                    "ga-label": props.gaLabel
                }, {
                    default: _withCtx(() => [
                        (props.isZjRefactorNavigationStrongEnabled)
                            ? (_openBlock(), _createBlock(Sessions, {
                                key: 0,
                                t: props.t,
                                "is-authenticated": props.isAuthenticated,
                                "has-pre-membership-links-only": props.hasPreMembershipLinksOnly,
                                active: props.active,
                                "current-user-locale": props.currentUserLocale,
                                "ga-label": props.gaLabel
                            }, null, 8, ["t", "is-authenticated", "has-pre-membership-links-only", "active", "current-user-locale", "ga-label"]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["active", "t", "is-strong-virtual-header-menu-enabled", "current-user-locale", "ga-label"]),
                _createVNode(Shop, {
                    t: props.t,
                    "ga-label": props.gaLabel,
                    "navigation-shop-url": props.navigationShopUrl,
                    "is-strong-shop-subnav-enabled": props.isStrongShopSubnavEnabled
                }, null, 8, ["t", "ga-label", "navigation-shop-url", "is-strong-shop-subnav-enabled"]),
                (props.isStrongResultsMenuEnabled)
                    ? (_openBlock(), _createBlock(About, {
                        key: 1,
                        t: props.t,
                        active: props.active,
                        "current-user-locale": props.currentUserLocale,
                        "ga-label": props.gaLabel,
                        "is-job-board-form-enabled": props.isJobBoardFormEnabled,
                        "is-gym-post-a-job-enabled": props.isGymPostAJobEnabled
                    }, null, 8, ["t", "active", "current-user-locale", "ga-label", "is-job-board-form-enabled", "is-gym-post-a-job-enabled"]))
                    : _createCommentVNode("", true),
                _createVNode(Benefits, {
                    active: props.active,
                    t: props.t,
                    "current-user-locale": props.currentUserLocale,
                    "ga-label": props.gaLabel,
                    "should-show-join-link": props.shouldShowJoinLink
                }, null, 8, ["active", "t", "current-user-locale", "ga-label", "should-show-join-link"])
            ], 64));
        };
    }
});
