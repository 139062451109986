import { get } from '@ts/Util/http';
import { prefixLocaleURL } from '@ts/Util/i18n';
import { csrfToken } from '@ts/Util/security';
import { readPageData } from "@ts/Util/page";
export const dataLayer = window.dataLayer || [];
export const vAnalytics = {
    mounted: (el, binding) => {
        const analytics = window.analytics || (() => ({ trackForm: () => null }));
        analytics.trackForm(el, binding.value.label, binding.value.data);
    }
};
export const trackLink = window.analytics?.trackLink || (() => console.info('noop function used because trackLink do not exists'));
export const track = window.analytics?.track || (() => console.info('noop function used because track do not exists'));
export const mixpanelTrack = (eventName, eventProperties) => {
    const mixpanel = window.mixpanel;
    if (mixpanel) {
        mixpanel.track(eventName, eventProperties);
    }
    else {
        console.info('noop function used because mixpanel do not exists');
    }
};
export const mixpanelServiceTrack = async (commandName, eventProperties) => {
    const mixpanel = window.mixpanel;
    if (mixpanel) {
        await saveMixpanelCookies();
        const body = {
            distinctID: getMixpanelDistinctID(),
            command_name: commandName,
            referrer: getReferrer(),
            page_url: window.location.href ?? null
        };
        await get(prefixLocaleURL(`/mixpanel/track_event`), csrfToken(), { ...body, ...eventProperties });
    }
    else {
        console.log('Mixpanel is not used on the page');
    }
};
export const getMixpanelDistinctID = () => {
    const mixpanel = window.mixpanel;
    // Mixpanel has some two-stage load, so make sure both parts got loaded:
    if (mixpanel && mixpanel.get_distinct_id) {
        const userPID = readPageData('userPID');
        let distinctID = mixpanel.get_distinct_id();
        if (userPID === null || userPID === '' || userPID === undefined) {
            // user is not authenticated.  We should reset the mixpanel
            // distinctID if it is not an anonymous ID, then return the new
            // anonymous mixpanel distinctID
            if (typeof distinctID !== 'string' || !distinctID.startsWith('$device:')) {
                mixpanel.reset();
                distinctID = mixpanel.get_distinct_id();
            }
            return distinctID;
        }
        if (distinctID == userPID) {
            // user is authenticated and the mixpanel distinctID is set to the
            // user's PID.
            return distinctID;
        }
        // user is authenticated but the mixpanel distinctID is not set to the
        // user's PID.  We should identify the user with mixpanel, then return
        // the user's PID
        mixpanel.identify(userPID);
        return userPID;
    }
    return null;
};
export const resetMixpanel = () => {
    const mixpanel = window.mixpanel;
    if (mixpanel) {
        mixpanel.reset();
    }
    clearDefaultCookies();
};
export const identifyUserMixpanel = (userPID) => {
    try {
        if (!userPID) {
            return;
        }
        const mixpanel = window.mixpanel;
        if (mixpanel) {
            mixpanel.identify(userPID);
            return;
        }
        console.error('Mixpanel user could not be identified');
    }
    catch (e) {
        console.error(e);
    }
};
const getReferrer = () => {
    try {
        return document.referrer;
    }
    catch (e) {
        return '';
    }
};
export const getMixpanelCookieObject = () => {
    const regex = /mp_[a-f0-9]+_mixpanel/;
    // Get all cookies
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        // Check if the cookie matches the regex pattern
        if (regex.test(cookie)) {
            // Extract the cookie value
            let mixpanelCookie = cookie.split('=')[1];
            let decodedData = decodeURIComponent(mixpanelCookie);
            const mixpanelCookies = JSON.parse(decodedData);
            // unset all the keys that start with __
            Object.keys(mixpanelCookies).forEach(key => {
                if (key.startsWith('__')) {
                    delete mixpanelCookies[key];
                }
            });
            return mixpanelCookies;
        }
    }
    // If no matching cookie found, return empty object
    return {};
};
const saveMixpanelCookies = async () => {
    const cookies = getMixpanelCookieObject();
    const deviceAndBrowser = {};
    deviceAndBrowser['device_category'] = detectDeviceCategory();
    deviceAndBrowser['browser'] = detectBrowser();
    await get(prefixLocaleURL(`/mixpanel/set_cookies`), csrfToken(), { ...cookies, ...deviceAndBrowser });
};
const clearDefaultCookies = async () => {
    await get(prefixLocaleURL(`/mixpanel/clear_cookies`), csrfToken());
};
const detectDeviceCategory = () => {
    const userAgent = navigator.userAgent;
    if (/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(userAgent)) {
        return 'Mobile';
    }
    else if (/Tablet|iPad/.test(userAgent)) {
        return 'Tablet';
    }
    else {
        return 'Desktop';
    }
};
const detectBrowser = () => {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/Android.*WebView/)) {
        return 'Android Webview';
    }
    else if (userAgent.match(/Chrome/)) {
        return 'Chrome';
    }
    else if (userAgent.match(/Safari/) && !userAgent.match(/Chrome/) && !userAgent.match(/CriOS/) && !userAgent.match(/FxiOS/)) {
        return 'Safari';
    }
    else if (userAgent.match(/SamsungBrowser/)) {
        return 'Samsung Internet';
    }
    else if (userAgent.match(/Edge/)) {
        return 'Edge';
    }
    else if (userAgent.match(/Safari/) && userAgent.match(/Version\/\d+\.\d+\.\d+\sMobile\/\w+\s(?:Safari\/\d+\.\d+)?/)) {
        return 'Safari (in-app)';
    }
    else if (userAgent.match(/Firefox/)) {
        return 'Firefox';
    }
    else if (userAgent.match(/Opera|OPR\//)) {
        return 'Opera';
    }
    else if (userAgent.match(/YaBrowser/)) {
        return 'YaBrowser';
    }
    else if (userAgent.match(/Silk/)) {
        return 'Amazon Silk';
    }
    else if (userAgent.match(/Mozilla\/\d+\.\d+\s\(compatible;/)) {
        return 'Mozilla Compatible Agent';
    }
    else if (userAgent.match(/Android.*Browser/)) {
        return 'Android Browser';
    }
    else {
        return 'Unknown';
    }
};
export const splitPagePath = () => {
    let path = window.location.pathname;
    return path.split("/").pop();
};
