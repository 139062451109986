import { createApp, defineComponent } from 'vue/dist/vue.esm-bundler';
import ZMenu from "@modules/Layout/nav/header/hamburger/Zumba/Menu.vue";
import UserMenu from "@modules/Layout/nav/header/hamburger/Strong/UserMenu.vue";
import Classes from "@modules/Layout/nav/header/hamburger/Strong/Classes.vue";
import Shop from "@modules/Layout/nav/header/hamburger/Strong/Shop.vue";
import About from "@modules/Layout/nav/header/hamburger/Strong/About.vue";
import Sessions from "@modules/Layout/nav/header/hamburger/Strong/Sessions.vue";
import Support from "@modules/Layout/nav/header/hamburger/Strong/Support.vue";
import Instructors from "@modules/Layout/nav/header/hamburger/Strong/Instructors.vue";
import Benefits from "@modules/Layout/nav/header/hamburger/Strong/Benefits.vue";
import Tag from '@components/Tags/Tag.vue';
const MenuContainer = defineComponent({
    name: 'MenuContainer',
    components: {
        ZMenu,
        UserMenu,
        Classes,
        Shop,
        About,
        Sessions,
        Support,
        Instructors,
        Benefits,
        Tag
    }
});
createApp(MenuContainer).mount('#s-menu-container');
