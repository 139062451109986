import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "ocn-list-item" };
const _hoisted_2 = ["data-ga-label", "data-ga4-details"];
const _hoisted_3 = ["href", "data-ga-label", "data-ga4-details"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'Shop',
    props: {
        t: {
            type: Object,
            default: () => ({
                shopText: 'Shop Clothing',
                globalShopText: 'Shop Clothing'
            })
        },
        gaLabel: { type: String, default: '' },
        navigationShopUrl: { type: String, default: '' },
        isStrongShopSubnavEnabled: { type: String, default: '' },
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("li", _hoisted_1, [
                (props.isStrongShopSubnavEnabled)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        class: "ocn-list-link",
                        href: "https://www.zumbawear.com/pages/strong-id-homepage",
                        title: "Shop",
                        "data-ga-category": "Navigation Menu",
                        "data-ga-action": "Shop",
                        "data-ga-label": props.gaLabel,
                        "data-ga4-event-name": "navigation_menu",
                        "data-ga4-action": "Shop",
                        "data-ga4-details": props.gaLabel
                    }, _toDisplayString(__props.t.globalShopText), 9, _hoisted_2))
                    : (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        class: "ocn-list-link",
                        href: props.navigationShopUrl,
                        title: "Shop",
                        "data-ga-category": "Navigation Menu",
                        "data-ga-action": "Shop",
                        "data-ga-label": props.gaLabel,
                        "data-ga4-event-name": "navigation_menu",
                        "data-ga4-action": "Shop",
                        "data-ga4-details": props.gaLabel
                    }, _toDisplayString(__props.t.shopText), 9, _hoisted_3))
            ]));
        };
    }
});
